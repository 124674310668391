class Elementor_Product {
    static instance;

    static getInstance() {
        if (!Elementor_Product.instance) {
            Elementor_Product.instance = new Elementor_Product();
        }
        return Elementor_Product.instance;
    }

    constructor() {
        $(window).on('elementor/frontend/init', () => {
            this.init();
        });
        this.tooltip();
    }

    tooltip() {
        // Tooltip
        let $body = $('body');
        $body.on('mouseenter', '.opal-add-to-cart-button:not(.tooltipstered)', function(){
            $(this)
                .tooltipster({
                    functionBefore: function(instance, helper) {
                        instance.content(instance._$origin.find('.button').text());
                    },
                    theme: 'opal-product-tooltipster',
                    delay: 0,
                    animation: 'grow',
                })
                .tooltipster('show');
        });

        $body.on('mouseenter', '.wooscp-btn, .woosq-btn, .woosw-btn, .compare-button .compare:not(.tooltipstered), .yith-wcqv-button:not(.tooltipstered), .yith-wcwl-add-to-wishlist > div > a:not(.tooltipstered)', function(){
            $(this)
                .tooltipster({
                    functionBefore: function(instance, helper) {
                        instance.content(instance._$origin.text());
                    },
                    theme: 'opal-product-tooltipster',
                    delay: 0,
                    animation: 'grow'
                })
                .tooltipster('show');
        });
    }

    init() {
        elementorFrontend.hooks.addAction('frontend/element_ready/opal-products.default', ($scope) => {
            let $carousel = $('.woocommerce-carousel', $scope);
            if ($carousel.length > 0) {
                let data = $carousel.data('settings');
                $('ul.products', $carousel).owlCarousel(Elementor_Carousel.setupData(data));
            }
            let $button = $scope.find('a.elementor-button-load-more');
            let $container = $scope.find('.elementor-widget-container .woocommerce ul.products');
            $button.on('click', function (event) {
                event.preventDefault();
                let settings = $button.data('settings');
                $.ajax({
                    url: osfAjax.ajaxurl,
                    data: {
                        action: 'osf_ajax_loadmore_products',
                        data: settings
                    },
                    type: 'POST',
                    dataType: 'JSON',
                    beforeSend: function () {
                        $('body').addClass('loading');
                    },
                    success: function (response) {
                        $container.append($(response.content).find('.products').html());
                        // $button.data("settings", response.settings);
                        if (response.disable) {
                            $button.remove();
                        }
                        $('body').removeClass('loading');
                    }
                });
            });


        });
    }

}

Elementor_Product.getInstance();

